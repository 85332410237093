'use client';
import { type Route } from 'next';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';

interface UseDelayedRouting {
  countdownStart: () => void;
  countdownStop: () => void;
  countdownTime: number;
}

export default function useDelayedRouting(
  route: Route,
  ms: number,
  immediate: boolean = true,
  callback?: () => void,
): UseDelayedRouting {
  const router = useRouter();
  const [countdownTime, setCountdownTime] = useState(ms / 1000);
  const countdownIntervalId = useRef(0);

  const countdownStart = useCallback((): void => {
    countdownIntervalId.current = window.setInterval(() => {
      setCountdownTime((pastState) => {
        return pastState - 1;
      });
    }, 1000);
  }, []);

  const countdownStop = useCallback((): void => {
    window.clearInterval(countdownIntervalId.current);
  }, []);

  useEffect(() => {
    if (immediate) {
      countdownStart();
    }
    return () => {
      countdownStop();
    };
  }, [countdownStart, countdownStop, immediate]);

  useEffect(() => {
    if (countdownTime <= 0) {
      countdownStop();
      if (callback !== undefined) {
        callback();
      }
      router.push(route);
    }
  }, [callback, countdownStop, countdownTime, route, router]);

  return { countdownStart, countdownStop, countdownTime };
}
