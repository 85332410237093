export const COUNTDOWN_TIMER_START = 5; // @TODO env?
export const WSP_DEBUG = false; // @TODO env?
export const ESP_DEBUG = false; // @TODO env?

export const transformErrorMessage = (error: Error): string => {
  if (error.message.startsWith('Bad Request: ')) {
    return error.message.replace('Bad Request: ', '');
  }
  return error.message;
};
