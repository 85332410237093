'use client';
import { WSP_DEBUG } from '@/helper/errors';
import React, { createContext, type MutableRefObject, useContext, useRef, useState } from 'react';

interface WebSocketContextState {
  connected: boolean;
  ws: MutableRefObject<WebSocket | null>;
  connect: () => Promise<WebSocket>;
  disconnect: () => Promise<void>;
}

export const WebSocketContext = createContext<WebSocketContextState | null>(null);

export function useWebSocket(): WebSocketContextState {
  const context = useContext(WebSocketContext);
  if (context === null) {
    throw new Error('useWebSocket must be used within WebSocketProvider');
  }

  return context;
}

interface WebSocketProviderProps {
  children: React.ReactNode;
  hostname: string;
  port: number;
}

export function WebSocketProvider({ children, hostname, port }: WebSocketProviderProps): React.JSX.Element {
  const [connected, setConnected] = useState<WebSocketContextState['connected']>(false);
  const ws = useRef<WebSocket | null>(null);

  const connect = async (): Promise<WebSocket> => {
    if (WSP_DEBUG) {
      console.log('Initiating websocket connection');
    }

    return await new Promise<WebSocket>((resolve, reject) => {
      const socketServer = `ws://${hostname}:${port}`;

      try {
        const socket = new WebSocket(socketServer);

        socket.onerror = (err) => {
          if (WSP_DEBUG) {
            console.log('websocket "' + hostname + ': ' + port + '" connection error');
          }
          setConnected(false);
          ws.current = null;
          reject(err);
        };

        socket.onopen = () => {
          if (WSP_DEBUG) {
            console.log('websocket "' + hostname + ': ' + port + '" connected');
          }
          ws.current = socket;
          setConnected(true);
          resolve(socket);
        };
      } catch (e) {
        if (WSP_DEBUG) {
          console.error(e);
        }
      }
    });
  };

  const disconnect = async (): Promise<void> => {
    if (WSP_DEBUG) {
      console.log('disconnecting websocket "' + hostname + ': ' + port + '"');
    }
    if (ws.current !== null) {
      ws.current.close();
      ws.current = null;
    } else {
      setConnected(false);
    }
  };

  return (
    <WebSocketContext.Provider value={{ connected, ws, connect, disconnect }}>{children}</WebSocketContext.Provider>
  );
}
