import { type Route } from 'next';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';

const WUINAV_PREFIX = '%WUINAV%'; // @TODO env var

// @TODO refactor? is it even possible? avoid using 'as'! maybe wait for a nextjs "typedRoutes" update. don't add a new dependency.
export const isWorkerUiRoute = (route: unknown): route is Route => {
  return typeof route === 'string';
};

export const isWorkerUiNavigation = (str: string): boolean => str.startsWith(WUINAV_PREFIX);

interface UseInputNavigation {
  exec: (wuiNav: string, callback?: () => Promise<void>) => Promise<boolean>;
  execSync: (wuiNav: string, callback?: () => void) => boolean;
}

export function useInputNavigation(): UseInputNavigation {
  const router = useRouter();

  const workerUiNavigationHandler = useCallback(
    (wuiNav: string) => {
      if (!isWorkerUiRoute(wuiNav)) {
        return;
      }
      router.push(wuiNav);
    },
    [router],
  );

  const exec = useCallback(
    async (wuiNav: string, callback?: () => Promise<void>) => {
      if (!isWorkerUiNavigation(wuiNav)) {
        return false;
      }

      if (callback !== undefined) {
        await callback();
      }

      workerUiNavigationHandler(encodeURI(wuiNav.slice(WUINAV_PREFIX.length)));
      return true;
    },
    [workerUiNavigationHandler],
  );

  const execSync = useCallback(
    (wuiNav: string, callback?: () => void) => {
      if (!isWorkerUiNavigation(wuiNav)) {
        return false;
      }

      if (callback !== undefined) {
        callback();
      }

      workerUiNavigationHandler(encodeURI(wuiNav.slice(WUINAV_PREFIX.length)));
      return true;
    },
    [workerUiNavigationHandler],
  );

  return { exec, execSync };
}
