import { type DependencyList, useCallback, useEffect, useRef, useState } from 'react';

export function useAsyncEffect<T>(
  asyncEffect: () => Promise<T>,
  destructor?: () => Promise<void>,
  dependencies: DependencyList = [],
): [T | undefined, boolean] {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(false);
  const executionChainRef = useRef<Promise<void>>(Promise.resolve());

  const runNext = useCallback((executor: () => Promise<void>): void => {
    executionChainRef.current = executionChainRef.current.then(executor, (error) => {
      console.error(`Error in asyncEffect chain: `, error);
    });
  }, []);

  useEffect(() => {
    mountedRef.current = true;

    const fetchData = async (): Promise<void> => {
      mountedRef.current && setLoading(true);
      try {
        if (mountedRef.current) {
          const result = await asyncEffect();
          mountedRef.current && setData(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        mountedRef.current && setLoading(false);
      }
    };
    runNext(fetchData);

    return () => {
      mountedRef.current = false;
      if (destructor !== undefined) {
        runNext(destructor);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runNext, mountedRef, ...dependencies]);

  return [data, loading];
}
