import { useThemeBreakpoint } from '@/components/useThemeBreakpoint';
import { type Breakpoint } from '@mui/material';
import { useCallback } from 'react';

export function useThemeBreakpointResolve(): <T>(breakpoints: Partial<Record<Breakpoint, T>>) => T | undefined {
  const themeBreakpoint = useThemeBreakpoint();

  return useCallback(
    <T>(breakpoints: Partial<Record<Breakpoint, T>>): T | undefined => {
      const breakpointOrder: Breakpoint[] = ['xl', 'lg', 'md', 'sm', 'xs'];

      const currentIndex = breakpointOrder.indexOf(themeBreakpoint);

      for (let i = currentIndex; i < breakpointOrder.length; i++) {
        const breakpoint = breakpointOrder[i];
        if (breakpoints[breakpoint] !== undefined) {
          return breakpoints[breakpoint];
        }
      }

      for (let i = currentIndex - 1; i >= 0; i--) {
        const breakpoint = breakpointOrder[i];
        if (breakpoints[breakpoint] !== undefined) {
          return breakpoints[breakpoint];
        }
      }

      return undefined;
    },
    [themeBreakpoint],
  );
}
