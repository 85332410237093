export const delay = async (ms: number): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, ms));
};

export const delayed = async (callback: () => void | Promise<void>, ms: number = 250): Promise<void> => {
  await delay(ms);
  void callback();
};

// @TODO naming
export const delayedSync = (callback: () => void | Promise<void>, ms: number = 250): void => {
  void (async () => {
    await delayed(callback, ms);
  })();
};
