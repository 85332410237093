import { type Breakpoint } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function useThemeBreakpoint(): Breakpoint {
  const theme = useTheme();

  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  const matchesMD = useMediaQuery('(min-height:594px)');
  const matchesLG = useMediaQuery('(min-height:890px)');
  const matchesXL = useMediaQuery('(min-height:980px)');

  const currentBreakpoint = xl ? 'xl' : lg ? 'lg' : md ? 'md' : sm ? 'sm' : 'xs';

  switch (currentBreakpoint) {
    case 'xl':
      return matchesXL ? 'xl' : matchesLG ? 'lg' : matchesMD ? 'md' : 'sm';
    case 'lg':
      return matchesLG ? 'lg' : matchesMD ? 'md' : 'sm';
    case 'md':
      return matchesMD ? 'md' : 'sm';
    case 'sm':
      return 'sm';
    case 'xs':
      return 'xs';
  }
}
